import { Box, Heading, Image, Text } from '@chakra-ui/react';
import React from 'react';
import Transaction from '../../../assets/transaction.png';
import Migration from '../../../assets/migrating.png';
import { useLocation } from 'react-router-dom';
import { Link, Outlet } from 'react-router-dom';
import Topbar from '../../global/topbar';
import { useSelector } from 'react-redux';

const Dashboard = () => {
	let location = useLocation();
	const companyName = useSelector((state) => state.companyName);
	return (
		<Box>
			{location.pathname === '/' ? (
				<Box
					borderRadius='6px'
					height='calc(100vh - 70px)'
					display='flex'
					flexDirection='column'
					alignItems='center'
					justifyContent='center'>
					<Topbar />
					<Box
						display='flex'
						flexDirection='column'
						justifyContent='center'
						alignItems='center'
						width='100vw'
						height='100vh'>
						<Heading fontSize='36px' marginBottom='50px'>
							{companyName} Data Migration
						</Heading>
						<Box
							display='flex'
							justifyContent='center'
							alignItems='center'>
							<Link to='/customer'>
								<Box
									borderRadius='15px'
									background='#FFF'
									boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px'
									marginRight='40px'
									padding='20px 15px'
									display='flex'
									flexDirection='column'
									justifyContent='center'
									alignItems='center'>
									<Image
										src={Migration}
										alt='Transaction'
										width='110px'
										height='110px'
										mb='20px'
									/>
									<Heading mb='20px' color='mainBlueColor'>
										Master Data Migration
									</Heading>
									<Text
										textAlign='center'
										color='textLightGrayColor'
										width='70%'
										margin='0 auto'>
										Data migration is the movement of data
										from one system to another.
									</Text>
								</Box>
							</Link>
							<Link to='/transaction'>
								<Box
									borderRadius='15px'
									background='#FFF'
									boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px'
									marginRight='40px'
									padding='20px 15px'
									display='flex'
									flexDirection='column'
									justifyContent='center'
									alignItems='center'>
									<Image
										src={Transaction}
										alt='Transaction'
										width='110px'
										height='110px'
										mb='20px'
									/>
									<Heading mb='20px' color='mainBlueColor'>
										Transaction Data Migration
									</Heading>
									<Text
										textAlign='center'
										color='textLightGrayColor'
										width='70%'
										margin='0 auto'>
										Data migration is the movement of data
										from one system to another.
									</Text>
								</Box>
							</Link>
						</Box>
					</Box>
				</Box>
			) : (
				<Box
					p='35px 40px'
					borderRadius='6px'
					height='100%'
					display='flex'
					alignItems='center'
					justifyContent='center'>
					<Box
						width='100%'
						borderRadius='6px'
						shadow='1px 1px 3px rgba(0,0,0,0.3)'
						padding='15px'
						height='100%'
						display='flex'
						flexDirection='column'
						alignItems='center'
						justifyContent='center'>
						<Topbar />
						<Outlet />
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default Dashboard;
