import React from 'react';
import Logo from '../../assets/Vitwo-AI-LOGO.png';
import {
	Box,
	Button,
	Image,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Text,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
	setSelectedLocation,
	setSelectedBranch,
	setLocation,
	setLocationCode,
	setCompanyId,
	setUserId,
} from '../../features/combinedReducers';

const Topbar = () => {
	const dispatch = useDispatch();
	let location = useLocation();
	const navigate = useNavigate();
	const locationName = useSelector((state) => state.location);
	const locationCode = useSelector((state) => state.locationCode);

	const handelLogout = () => {
		dispatch(setSelectedBranch(null));
		dispatch(setSelectedLocation(null));
		dispatch(setLocation(null));
		dispatch(setLocationCode(null));
		dispatch(setCompanyId(null));
		dispatch(setUserId(null));
		navigate('/login');
	};

	return (
		<Box
			width='100%'
			boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px'
			borderRadius='5px'
			mb='20px'
			display='flex'
			justifyContent='space-between'
			alignItems='center'
			p='10px'>
			<Link to='/'>
				<Image src={Logo} height='30px' w='auto' />
			</Link>
			{location.pathname === '/select-location' ? (
				''
			) : (
				<Box
					display='flex'
					alignItems='center'
					justifyContent='space-between'>
					<Link to='/'>
						<Button
							bgGradient='linear(180deg, #2267A2 0%, #0D4675 100%)'
							boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
							borderRadius='50px'
							height='50px'
							width='50px'
							marginRight='15px'
							fontSize='1.6rem'
							color='white'
							type='submit'
							_hover={{
								bgGradient:
									'linear(180deg, #2267A2 0%, #0D4675 100%)',
							}}
							_active={{
								bgGradient:
									'linear(180deg, #2267A2 0%, #0D4675 100%)',
							}}
							_focus={{
								bgGradient:
									'linear(180deg, #2267A2 0%, #0D4675 100%)',
							}}>
							<i className='fa-solid fa-house'></i>
						</Button>
					</Link>
					<Menu>
						<MenuButton
							as={Button}
							rightIcon={<ChevronDownIcon />}
							bgGradient='linear(180deg, #2267A2 0%, #0D4675 100%)'
							boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
							borderRadius='10px'
							padding='25px 15px'
							fontSize='1.6rem'
							color='white'
							_hover={{
								bgGradient:
									'linear(180deg, #2267A2 0%, #0D4675 100%)',
							}}
							_active={{
								bgGradient:
									'linear(180deg, #2267A2 0%, #0D4675 100%)',
							}}
							_focus={{
								bgGradient:
									'linear(180deg, #2267A2 0%, #0D4675 100%)',
							}}>
							<Box
								display='flex'
								flexDirection='column'
								justifyContent='space-between'
								alignItems='flex-start'
								marginRight='20px'>
								<Text
									fontWeight='600'
									fontSize='1.6rem'
									color='white'>
									{locationName}
								</Text>
								<Text
									fontWeight='600'
									fontSize='1.2rem'
									color='gray.300'>
									{locationCode}
								</Text>
							</Box>
						</MenuButton>
						<MenuList
							sx={{
								'& .chakra-menu__menuitem': {
									fontWeight: '600',
									color: 'var(--chakra-colors-mainBlueColor)',
									padding: '10px',
								},
							}}>
							<Link to='/select-location'>
								<MenuItem>Change Location</MenuItem>
							</Link>
							<MenuItem onClick={handelLogout}>Logout</MenuItem>
						</MenuList>
					</Menu>
				</Box>
			)}
		</Box>
	);
};

export default Topbar;
