import React, { useCallback, useState } from 'react';
import * as XLSX from 'xlsx';
import { useDropzone } from 'react-dropzone';
import {
	Box,
	Button,
	Image,
	Input,
	Text,
	Progress,
	ButtonGroup,
	Flex,
	Heading,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import uploadIcon from '../../../assets/upload_icon.png';
import AutoTypeText from './autotype';
import { useSelector } from 'react-redux';
import excelFile from '../../../assets/master/customer-master.csv';
import CustomerPaginatedData from './customerPaginatedData';

const Customer = React.memo(() => {
	const progress = 33.33;
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [editing, setEditing] = useState(false);
	const locationCode = useSelector((state) => state.locationCode);
	const [uploadMessageExcel, setUploadMessageExcel] = useState(undefined); // Initialize as undefined
	const [hasValidationErrors, setHasValidationErrors] = useState(false);
	const [errors, setErrors] = useState([]);
	const [errorValue, setErrorValue] = useState();
	const [pagesWithErrors, setPagesWithErrors] = useState(new Set());
	const [currentPage, setCurrentPage] = useState(1); // Current page
	const itemsPerPage = 50; // Number of items to display per page

	function formatDateToDdMmYy(date) {
		const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with leading zero if needed
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (add 1 as months are zero-indexed) and pad with leading zero if needed
		const year = String(date.getFullYear()).slice(-2); // Get the last 2 digits of the year

		return `${day}-${month}-${year}`;
	}
	const currentDate = new Date();
	const formattedDate = formatDateToDdMmYy(currentDate);

	const handleFileDrop = useCallback((acceptedFiles) => {
		const file = acceptedFiles[0];
		const reader = new FileReader();

		reader.onload = (event) => {
			const data = new Uint8Array(event.target.result);
			const workbook = XLSX.read(data, { type: 'array' });

			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];

			const excelData = XLSX.utils.sheet_to_json(worksheet, {
				header: 1,
			});

			const headers = excelData[0].map((header) => header.trim());
			const formattedData = excelData.slice(1).map((row) => {
				const rowData = {};
				row.forEach((cell, columnIndex) => {
					const header = headers[columnIndex];
					rowData[header] = cell;
				});
				return rowData;
			});

			// Fill missing keys with empty strings
			const emptyRow = Object.fromEntries(
				headers.map((header) => [header, ''])
			);
			const dataWithEmptyKeys = formattedData.map((row) => ({
				...emptyRow,
				...row,
			}));

			// Filter out blank rows
			const nonBlankRows = dataWithEmptyKeys.filter((row) => {
				return Object.values(row).some(
					(cellValue) => cellValue !== null && cellValue !== ''
				);
			});
			setData(nonBlankRows);
			setUploadMessageExcel(undefined);
		};

		reader.readAsArrayBuffer(file);
	});

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: handleFileDrop,
		accept: '.xlsx',
	});

	const handleDownload = () => {
		const link = document.createElement('a');
		link.href = excelFile;
		link.setAttribute(
			'download',
			`customer-master-${locationCode}-${formattedDate}.csv`
		);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};

	return (
		<Box width='100%'>
			<Box position='relative' mb='99px'>
				<Progress
					position='relative'
					colorScheme='blue'
					value={progress}
					mb='50px'
					mt='40px'
					isAnimated></Progress>

				<Box
					display='flex'
					flexDirection='column'
					alignItems='center'
					position='absolute'
					top='-30px'
					left='15%'
					color='white'
					fontWeight='600'
					fontSize='1.3rem'
					background='var(--chakra-colors-blue-500)'
					padding='10px'
					borderRadius='50px'
					w='70px'
					h='70px'>
					<Box
						bg='claimzIconGreentColor'
						borderRadius='50px'
						display='flex'
						justifyContent='center'
						alignItems='center'>
						1
					</Box>
					<Box as='span'>Customer</Box>
				</Box>

				<Box
					display='flex'
					flexDirection='column'
					alignItems='center'
					position='absolute'
					top='-30px'
					left='47%'
					color='mainBlueColor'
					fontWeight='600'
					fontSize='1.3rem'
					background='var(--chakra-colors-bgGraycolor)'
					padding='10px'
					borderRadius='50px'
					w='70px'
					h='70px'>
					<Box
						bg='claimzIconGreentColor'
						borderRadius='50px'
						display='flex'
						justifyContent='center'
						alignItems='center'>
						2
					</Box>
					<Box as='span'>Vendor</Box>
				</Box>

				<Box
					display='flex'
					flexDirection='column'
					alignItems='center'
					position='absolute'
					top='-30px'
					right='15%'
					color='mainBlueColor'
					fontWeight='600'
					fontSize='1.3rem'
					background='var(--chakra-colors-bgGraycolor)'
					padding='10px'
					borderRadius='50px'
					w='70px'
					h='70px'>
					<Box
						bg='claimzIconGreentColor'
						borderRadius='50px'
						display='flex'
						justifyContent='center'
						alignItems='center'>
						3
					</Box>
					<Box as='span'>Items</Box>
				</Box>
			</Box>

			<Box
				width='100%'
				height='100%'
				display='flex'
				justifyContent='center'
				alignItems='center'
				flexDirection='column'>
				<Box w='100%' display='flex' justifyContent='space-around'>
					<Box
						width='38%'
						display='flex'
						justifyContent='space-around'
						alignItems='center'>
						<Box width='400px'>
							<Box
								border='2px dashed var(--chakra-colors-bgGraycolor)'
								p='15px'
								borderRadius='20px'
								opacity={editing ? '0.5' : '1'}
								pointerEvents={editing ? 'none' : 'painted'}>
								<Box
									{...getRootProps()}
									bg='var(--chakra-colors-mainBlueColor)'
									width='100%'
									height='100%'
									borderRadius='20px'
									display='flex'
									justifyContent='center'
									alignItems='center'
									color='white'
									padding='50px 0px'
									cursor='grab'>
									<Input {...getInputProps()} />
									{isDragActive ? (
										<Box
											display='flex'
											flexDirection='column'
											justifyContent='center'
											alignItems='center'>
											<Image
												src={uploadIcon}
												alt='upload icon'
												height='100px'
												width='100px'
											/>
											<Text>
												Drop the Excel file here...
											</Text>
										</Box>
									) : (
										<Box
											display='flex'
											flexDirection='column'
											justifyContent='center'
											alignItems='center'>
											<Image
												src={uploadIcon}
												alt='upload icon'
												height='100px'
												width='100px'
											/>
											<Text>Drag & Drop your files</Text>
										</Box>
									)}
								</Box>
							</Box>
							<Box
								display='flex'
								width='100%'
								justifyContent='center'
								gap='15px'
								paddingTop='45px'>
								<Button
									borderRadius='10px'
									border='1px solid #003060'
									p='20px 30px'
									width='50%'
									fontSize='15px'
									background='none'
									color='mainBlueColor'
									onClick={handleDownload}
									cursor='pointer'>
									Download Sample
								</Button>
							</Box>
						</Box>
					</Box>

					<Box
						width='58%'
						boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px'
						borderRadius='15px'
						padding='15px'>
						<Heading
							mb='15px'
							color='var(--chakra-colors-mainBlueColor)'
							fontSize='28px'>
							Data Summary :{' '}
						</Heading>
						<Text
							fontSize='1.7rem'
							fontWeight='600'
							marginBottom='10px'
							color='mainBlueColor'>
							Total lines - {data?.length}
						</Text>
						<Text
							fontSize='1.7rem'
							fontWeight='600'
							marginBottom='10px'
							color='red'>
							Total Error : {errorValue}
						</Text>
						<Box mt='10px' mb='10px'>
							<Box
								fontSize='1.7rem'
								fontWeight='600'
								color='mainBlueColor'>
								Pages with errors:
							</Box>

							{Array.from(pagesWithErrors).join(', ')}
						</Box>
						<Box>
							<Text
								fontSize='1.7rem'
								fontWeight='600'
								color='mainBlueColor'
								marginBottom='5px'>
								Data Review Report -
							</Text>
							<AutoTypeText />
						</Box>
					</Box>
				</Box>
				<CustomerPaginatedData
					data={data}
					setData={setData}
					currentPage={currentPage}
					itemsPerPage={itemsPerPage}
					editing={editing}
					setEditing={setEditing}
					onPageChange={handlePageChange}
					setErrorValue={setErrorValue}
					errors={errors}
					setErrors={setErrors}
					hasValidationErrors={hasValidationErrors}
					setHasValidationErrors={setHasValidationErrors}
					setPagesWithErrors={setPagesWithErrors}
					uploadMessageExcel={uploadMessageExcel}
					setUploadMessageExcel={setUploadMessageExcel}
				/>
			</Box>

			<ButtonGroup w='100%'>
				<Flex w='100%' justifyContent='flex-end'>
					<Button
						mt='30px'
						bgGradient='linear(180deg, #2267A2 0%, #0D4675 100%)'
						boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
						borderRadius='10px'
						p='20px 40px'
						fontSize='1.6rem'
						color='white'
						_hover={{
							bgGradient:
								'linear(180deg, #2267A2 0%, #0D4675 100%)',
						}}
						_active={{
							bgGradient:
								'linear(180deg, #2267A2 0%, #0D4675 100%)',
						}}
						_focus={{
							bgGradient:
								'linear(180deg, #2267A2 0%, #0D4675 100%)',
						}}
						onClick={() => navigate('/vendor')}>
						Next
					</Button>
				</Flex>
			</ButtonGroup>
		</Box>
	);
});

export default Customer;
