import { ChakraProvider } from '@chakra-ui/react';
import ExtendedTheme from './modules/global/chakraExtendTheme';
import GlobalCss from './modules/global/index';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Customer from './modules/pages/multistepform/customer';
import './App.css';
import Vendor from './modules/pages/multistepform/vendor';
import Items from './modules/pages/multistepform/items';
import OtpScreen from './modules/pages/authentication/otpscreen';
import Authentication from './modules/pages/authentication/index';
import Dashboard from './modules/pages/home';
import { useSelector } from 'react-redux';
import Transaction from './modules/pages/transaction';
import Selectlocation from './modules/pages/home/selectlocation';

function App() {
	const userId = useSelector((state) => state.userId);
	const location = useSelector((state) => state.location);
	const companyId = useSelector((state) => state.companyId);

	return (
		<ChakraProvider theme={ExtendedTheme}>
			<GlobalCss />
			<BrowserRouter>
				<Routes>
					{location ? (
						<>
							<Route path='/:otpCode' element={<Dashboard />}>
								<Route path='customer' element={<Customer />} />

								<Route path='vendor' element={<Vendor />} />

								<Route path='items' element={<Items />} />

								<Route
									path='transaction'
									element={<Transaction />}
								/>
							</Route>

							<Route path='/' element={<Dashboard />}>
								<Route
									path='/customer'
									element={<Customer />}
								/>

								<Route path='/vendor' element={<Vendor />} />

								<Route path='/items' element={<Items />} />

								<Route
									path='/transaction'
									element={<Transaction />}
								/>
							</Route>
						</>
					) : (
						<Route path='/:otpCode' element={<Authentication />} />
					)}
					{userId ? (
						<Route
							path='select-location'
							element={<Selectlocation />}
						/>
					) : (
						<Route path='/:otpCode' element={<OtpScreen />} />
					)}
					<Route path='/verify' element={<OtpScreen />} />
				</Routes>
			</BrowserRouter>
		</ChakraProvider>
	);
}

export default App;
