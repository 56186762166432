import React from 'react';
import { Tab, Tabs, TabList, TabPanel, TabPanels, Box } from '@chakra-ui/react';
import CustomerBalance from './customerbalance';
import CustomerInvoice from './customerinvoice';

const Customer = () => {
	return (
		<Box w='100%'>
			<Tabs
				bg='white'
				boxShadow='md'
				border='none'
				borderRadius='0px 0px 5px 5px'
				size='lg'
				isManual
				variant='enclosed'>
				<TabList
					bg='var(--chakra-colors-mainBlueColor)'
					boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
					color='white'
					padding='10px 15px'>
					<Tab
						_selected={{
							color: 'var(--chakra-colors-mainBlueColor)',
							bg: 'white',
						}}
						fontSize='1.4rem'
						borderRadius='5px'
						fontWeight='600'>
						Customer Balance
					</Tab>
					<Tab
						_selected={{
							color: 'var(--chakra-colors-mainBlueColor)',
							bg: 'white',
						}}
						fontSize='1.4rem'
						borderRadius='5px'
						fontWeight='600'>
						Customer Invoice
					</Tab>
				</TabList>

				<TabPanels>
					<TabPanel>
						<CustomerBalance />
					</TabPanel>
					<TabPanel>
						<CustomerInvoice />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Box>
	);
};

export default Customer;
