// import { configureStore } from '@reduxjs/toolkit';
// import combinedReducers from '../features/combinedReducers';

// export const store = configureStore({
// 	reducer: {
// 		show: combinedReducers,
// 	},
// });

// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import counterReducer from '../features/combinedReducers';

const persistConfig = {
	key: 'root',
	storage,
};

const persistedReducer = persistReducer(persistConfig, counterReducer);

export const store = configureStore({
	reducer: persistedReducer,
});

export const persistor = persistStore(store);
