import React, { useState, useEffect } from 'react';
import { Box, Text, Button, Image, useToast } from '@chakra-ui/react';
import Vitwo from '../../../assets/vitwo-ai.png';
import Logo from '../../../assets/Vitwo-AI-LOGO.png';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setCompanyId, setUserId } from '../../../features/combinedReducers';
import { BeatLoader } from 'react-spinners';

const Authentication = () => {
	const toast = useToast();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const companyId = useSelector((state) => state.companyId);
	const userId = useSelector((state) => state.userId);

	const { otpCode } = useParams();
	function toastCall() {
		return toast({
			title: 'OTP Send Sucessfully',
			status: 'success',
			duration: 3000,
			isClosable: true,
		});
	}

	function toastCallError() {
		return toast({
			title: 'OTP Sending Failed',
			status: 'error',
			duration: 3000,
			isClosable: true,
		});
	}

	function decodeBase64Twice(encodedString) {
		try {
			const decodedOnce = window.atob(encodedString);
			const decodedTwice = window.atob(decodedOnce);
			return decodedTwice;
		} catch (e) {
			console.error(e.message);
			return '';
		}
	}
	const base64String = otpCode;
	const decodedValue = decodeBase64Twice(base64String);

	useEffect(() => {
		dispatch(setCompanyId(decodedValue.split('|')[0]));
		dispatch(setUserId(decodedValue.split('|')[1]));
	}, [dispatch, decodedValue]);

	const sendOtp = async (e) => {
		e.preventDefault();
		let formData = new FormData();
		formData.append('company_id', companyId);
		formData.append('user_id', userId);

		try {
			setIsLoading(true);
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}resend-otp.php`,
				{
					method: 'POST',
					body: formData,
				}
			);

			if (response.ok) {
				toastCall();
				setIsLoading(false);
				navigate('/verify');
			} else {
				toastCallError();
				setIsLoading(false);
			}
		} catch (error) {
			toastCallError();
			setIsLoading(false);
		}
	};

	return (
		<Box
			display='flex'
			alignItems='center'
			justifyContent='center'
			bgColor='#e2e1ff'
			height='100vh'
			w='100vw'>
			<Box
				boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px'
				borderRadius='15px'
				display='flex'
				alignItems='center'
				justifyContent='center'
				flexWrap='wrap'
				bgColor='white'
				p='20px'>
				<Box
					className='otp-verification'
					width={{ base: '100%', md: '400px' }}
					height='350px'
					display='flex'
					alignItems='center'
					justifyContent='center'
					borderRadius='10px'
					textAlign='center'>
					<Box
						display='flex'
						flexDirection='column'
						justifyContent='center'
						alignItems='center'>
						<Image
							src={Logo}
							alt='logo'
							height='auto'
							width='70%'
							mb='20px'
						/>
						<Text>
							Data migration is the process of selecting,
							preparing, extracting, and transforming data and
							permanently transferring it from one computer
							storage system to another.{}
						</Text>
						<Link to='/verify'>
							{decodedValue != '' ? (
								<Button
									disabled={isLoading}
									isLoading={isLoading}
									spinner={
										<BeatLoader size={8} color='white' />
									}
									mt='20px'
									mb='5px'
									bgGradient='linear(180deg, #2267A2 0%, #0D4675 100%)'
									boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
									borderRadius='10px'
									p='20px 40px'
									fontSize='1.6rem'
									color='white'
									_hover={{
										bgGradient:
											'linear(180deg, #2267A2 0%, #0D4675 100%)',
									}}
									_active={{
										bgGradient:
											'linear(180deg, #2267A2 0%, #0D4675 100%)',
									}}
									_focus={{
										bgGradient:
											'linear(180deg, #2267A2 0%, #0D4675 100%)',
									}}
									onClick={sendOtp}>
									Verify Yourself
								</Button>
							) : (
								<Text fontWeight='600' color='red'>
									Please check your link and try again!
								</Text>
							)}
						</Link>
					</Box>
				</Box>
				<Box
					display={{ base: 'none', md: 'flex' }}
					width={{ base: '100%', md: '400px' }}
					background='white'
					padding='15px'
					height='350px'
					p='20px'
					borderRadius='15px'
					alignItems='center'
					justifyContent='center'>
					<Image src={Vitwo} alt='vitwo ai' />
				</Box>
			</Box>
		</Box>
	);
};

export default Authentication;
