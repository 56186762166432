import React from 'react';
import {
	Box,
	Button,
	Progress,
	ButtonGroup,
	Flex,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	TabIndicator,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import RawMaterial from './rawmaterial';
import SemiFinishedGoods from './semifinishedgoods';
import FinishedGoodsManufacturing from './finishedgoodsmanufacturing';
import FinishedGoodstrading from './finishedgoodstrading';
import ServicesSales from './servicessales';
import ServicesPurchased from './servicespurchased';
import Assets from './assets';

const Items = () => {
	const navigate = useNavigate();
	const progress = 100;

	return (
		<Box width='100%'>
			<Box position='relative' mb='50px'>
				<Progress
					position='relative'
					colorScheme='blue'
					value={progress}
					mb='50px'
					mt='40px'
					isAnimated></Progress>

				<Box
					display='flex'
					flexDirection='column'
					alignItems='center'
					position='absolute'
					top='-30px'
					left='15%'
					color='mainBlueColor'
					fontWeight='600'
					fontSize='1.3rem'
					background='var(--chakra-colors-bgGraycolor)'
					padding='10px'
					borderRadius='50px'
					w='70px'
					h='70px'>
					<Box
						bg='claimzIconGreentColor'
						borderRadius='50px'
						display='flex'
						justifyContent='center'
						alignItems='center'>
						1
					</Box>
					<Box as='span'>Customer</Box>
				</Box>

				<Box
					display='flex'
					flexDirection='column'
					alignItems='center'
					position='absolute'
					top='-30px'
					left='47%'
					color='mainBlueColor'
					fontWeight='600'
					fontSize='1.3rem'
					background='var(--chakra-colors-bgGraycolor)'
					padding='10px'
					borderRadius='50px'
					w='70px'
					h='70px'>
					<Box
						bg='claimzIconGreentColor'
						borderRadius='50px'
						display='flex'
						justifyContent='center'
						alignItems='center'>
						2
					</Box>
					<Box as='span'>Vendor</Box>
				</Box>

				<Box
					display='flex'
					flexDirection='column'
					alignItems='center'
					position='absolute'
					top='-30px'
					right='15%'
					color='white'
					fontWeight='600'
					fontSize='1.3rem'
					background='var(--chakra-colors-blue-500)'
					padding='10px'
					borderRadius='50px'
					w='70px'
					h='70px'>
					<Box
						bg='claimzIconGreentColor'
						borderRadius='50px'
						display='flex'
						justifyContent='center'
						alignItems='center'>
						3
					</Box>
					<Box as='span'>Items</Box>
				</Box>
			</Box>

			<Tabs position='relative' variant='unstyled'>
				<TabList
					sx={{
						'& .chakra-tabs__tab': {
							borderRadius: '15px 15px 0px 0px',
							border: 'none',
							color: 'var(--chakra-colors-mainBlueColor)',
							fontSize: '1.6rem',
							fontWeight: '700',
							pb: '10px',
							pt: '10px',
						},
						'& .chakra-tabs__tab[aria-selected=true]': {
							borderRadius: '15px 15px 0px 0px',
							color: 'white',
							bg: 'var(--chakra-colors-mainBlueColor)',
						},
					}}>
					<Tab>Raw Material</Tab>
					<Tab>Semi-Finished Goods</Tab>
					<Tab>Finished Goods (Manufacturing)</Tab>
					<Tab>Finished Goods(Trading)</Tab>
					<Tab>Services Sales</Tab>
					<Tab>Services Purchased</Tab>
					<Tab>Assets</Tab>
				</TabList>
				<TabIndicator
					mt='-1.5px'
					height='2px'
					bg='var(--chakra-colors-mainBlueColor)'
					borderRadius='1px'
				/>
				<TabPanels
					sx={{
						'& .chakra-tabs__tab-panel': {
							height: '100%',
							boxShadow: '1px 1px 3px rgba(0,0,0,0.3)',
						},
					}}>
					<TabPanel>
						<RawMaterial />
					</TabPanel>
					<TabPanel>
						<SemiFinishedGoods />
					</TabPanel>
					<TabPanel>
						<FinishedGoodsManufacturing />
					</TabPanel>
					<TabPanel>
						<FinishedGoodstrading />
					</TabPanel>
					<TabPanel>
						<ServicesSales />
					</TabPanel>
					<TabPanel>
						<ServicesPurchased />
					</TabPanel>
					<TabPanel>
						<Assets />
					</TabPanel>
				</TabPanels>
			</Tabs>

			<ButtonGroup w='100%'>
				<Flex w='100%' justifyContent='space-between'>
					<Button
						mr='20px'
						mt='20px'
						bgGradient='linear(180deg, #2267A2 0%, #0D4675 100%)'
						boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
						borderRadius='10px'
						p='20px 40px'
						fontSize='1.6rem'
						color='white'
						_hover={{
							bgGradient:
								'linear(180deg, #2267A2 0%, #0D4675 100%)',
						}}
						_active={{
							bgGradient:
								'linear(180deg, #2267A2 0%, #0D4675 100%)',
						}}
						_focus={{
							bgGradient:
								'linear(180deg, #2267A2 0%, #0D4675 100%)',
						}}
						onClick={() => navigate('/vendor')}>
						Previous
					</Button>
				</Flex>
			</ButtonGroup>
		</Box>
	);
};

export default Items;
