import React from 'react';
import {
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	TabIndicator,
} from '@chakra-ui/react';
import RawMaterial from './rawmaterial';
import FinishGoods from './finishedgoods';
import SemiFinishedGoods from './semifinishedgoods';
import Assets from './assets';
import FinishedGoodsManufacturing from './finishedgoodsmanufacturing';

const ItemValue = () => {
	return (
		<>
			<Tabs position='relative' variant='unstyled' w='100%'>
				<TabList
					sx={{
						'& .chakra-tabs__tab': {
							borderRadius: '15px 15px 0px 0px',
							border: 'none',
							color: 'var(--chakra-colors-mainBlueColor)',
							fontSize: '1.6rem',
							fontWeight: '700',
							pb: '10px',
							pt: '10px',
						},
						'& .chakra-tabs__tab[aria-selected=true]': {
							borderRadius: '15px 15px 0px 0px',
							color: 'white',
							bg: 'var(--chakra-colors-mainBlueColor)',
						},
					}}>
					<Tab>Raw Material</Tab>
					<Tab>Semi Finished Goods</Tab>
					<Tab>Finish Goods - Trading</Tab>
					<Tab>Finish Goods - Manufacturing</Tab>
					<Tab>Assets</Tab>
				</TabList>
				<TabIndicator
					height='2px'
					bg='var(--chakra-colors-mainBlueColor)'
					borderRadius='1px'
				/>
				<TabPanels
					minHeight='calc(100vh - 221px)'
					sx={{
						'& .chakra-tabs__tab-panel': {
							display: 'flex',
							alignItems: 'center',
							minHeight: '485px',
							boxShadow: '1px 1px 3px rgba(0,0,0,0.3)',
						},
					}}>
					<TabPanel>
						<RawMaterial />
					</TabPanel>
					<TabPanel>
						<SemiFinishedGoods />
					</TabPanel>
					<TabPanel>
						<FinishGoods />
					</TabPanel>
					<TabPanel>
						<FinishedGoodsManufacturing />
					</TabPanel>
					<TabPanel>
						<Assets />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</>
	);
};

export default ItemValue;
