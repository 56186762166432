import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { store, persistor } from './app/store';
import './index.css';

const rootElement = document.getElementById('root');

const renderApp = () => {
	ReactDOM.createRoot(rootElement).render(
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>
	);
};

// Check if the createRoot method is available
if (rootElement.createReactRoot) {
	rootElement.createReactRoot();
}

// Render the app
renderApp();
