import React from 'react';
import { Global } from '@emotion/react';

const GlobalCss = () => {
	return (
		<Global
			styles={`
            .otp-field {
              flex-direction: row;
              column-gap: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .otp-field input {
              height: 45px;
              width: 40px;
              border-radius: 6px;
              outline: none;
              font-size: 1.6rem;
              font-weight: bold;
              text-align: center;
              border: 2px solid #c2c2c2;
            }
            .otp-field input:focus {
              box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
            }
            .otp-field input::-webkit-inner-spin-button,
            .otp-field input::-webkit-outer-spin-button {
              display: none;
            }
            .resend {
              font-size: 12px;
            }
            .footer {
              position: absolute;
              bottom: 10px;
              right: 10px;
              color: black;
              font-size: 12px;
              text-align: right;
              font-family: monospace;
            }
            .footer a {
              color: black;
              text-decoration: none;
            }

            // media Query globally
            @media only screen and (max-width: 1023px) {

              }
            @media (min-width: 768px) and (max-width: 1023px) {

              }
            @media (min-width: 980px) and (max-width: 1023px) {
              }
        `}
		/>
	);
};

export default GlobalCss;
