import { Box, Text, Button, Input, Image, useToast } from '@chakra-ui/react';
import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Vitwo from '../../../assets/vitwo-ai.png';
import Logo from '../../../assets/Vitwo-AI-LOGO.png';
import { BeatLoader } from 'react-spinners';

const OtpScreen = () => {
	const toast = useToast();
	const inputRefs = useRef([]);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [isButtonActive, setIsButtonActive] = useState(false);
	const [timer, setTimer] = useState(true);
	const [countdown, setCountdown] = useState(300);
	const companyId = useSelector((state) => state.companyId);
	const userId = useSelector((state) => state.userId);

	function toastCall() {
		return toast({
			title: 'Verification Sucessfully Completed',
			status: 'success',
			duration: 3000,
			isClosable: true,
		});
	}

	function toastCallError() {
		return toast({
			title: 'Verification Failed',
			status: 'error',
			duration: 3000,
			isClosable: true,
		});
	}

	useEffect(() => {
		inputRefs.current[0].focus();
	}, []);

	useEffect(() => {
		// Clear the input fields when isButtonActive becomes false
		if (!isButtonActive) {
			inputRefs.current.forEach((ref) => (ref.value = ''));
		}
	}, [isButtonActive]);

	useEffect(() => {
		let countdownInterval;
		if (timer) {
			countdownInterval = setInterval(() => {
				setCountdown((prevCountdown) => {
					if (prevCountdown === 0) {
						setIsButtonActive(false); // Stop the countdown when it reaches zero
						clearInterval(countdownInterval); // Clear the interval
					}
					return prevCountdown - 1;
				});
			}, 1000);
		}

		// Clear the interval when the component unmounts or the button is no longer active
		return () => {
			clearInterval(countdownInterval);
		};
	}, [isButtonActive, timer]);

	const requestButtonClick = async (e) => {
		e.preventDefault();
		setIsButtonActive(!isButtonActive);
		setCountdown(10);

		let formData = new FormData();
		formData.append('company_id', companyId);
		formData.append('user_id', userId);

		try {
			setIsLoading(true);
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}resend-otp.php`,
				{
					method: 'POST',
					body: formData,
				}
			);

			if (response.ok) {
				toastCall();
				setIsLoading(false);
				navigate('/verify');
			} else {
				toastCallError();
			}
		} catch (error) {
			toastCallError();
		}
	};

	const handleInputChange = (index, event) => {
		const { key, target } = event;
		const nextIndex = index + 1;
		const prevIndex = index - 1;

		if (target.value.length > 1) {
			target.value = '';
			return;
		}

		if (
			key !== 'Backspace' &&
			nextIndex < inputRefs.current.length &&
			target.value !== ''
		) {
			inputRefs.current[nextIndex].removeAttribute('disabled');
			inputRefs.current[nextIndex].focus();
		}

		if (key === 'Backspace' && index !== 0) {
			for (let i = index; i < inputRefs.current.length; i++) {
				inputRefs.current[i].setAttribute('disabled', true);
				inputRefs.current[i].value = '';
			}
			if (prevIndex >= 0) {
				inputRefs.current[prevIndex].focus();
			}
		}

		const lastInputIndex = inputRefs.current.length - 1;
		const isLastInputFilled =
			!inputRefs.current[lastInputIndex].hasAttribute('disabled') &&
			inputRefs.current[lastInputIndex].value !== '';
		setIsButtonActive(isLastInputFilled);
	};

	// const handleInputChange = (index, event) => {
	// 	const { key, target } = event;
	// 	const nextIndex = index + 1;
	// 	const prevIndex = index - 1;

	// 	if (target.value.length > 1) {
	// 		target.value = '';
	// 		return;
	// 	}

	// 	// Check if the key is Backspace and the index is not 0
	// 	if (key === 'Backspace' && index !== 0) {
	// 		for (let i = index; i < inputRefs.current.length; i++) {
	// 			inputRefs.current[i].setAttribute('disabled', true);
	// 			inputRefs.current[i].value = '';
	// 		}
	// 		if (prevIndex >= 0) {
	// 			inputRefs.current[prevIndex].focus();
	// 		}
	// 	} else if (
	// 		key !== 'Backspace' &&
	// 		nextIndex < inputRefs.current.length &&
	// 		target.value !== ''
	// 	) {
	// 		inputRefs.current[nextIndex].removeAttribute('disabled');
	// 		inputRefs.current[nextIndex].focus();
	// 	}

	// 	const lastInputIndex = inputRefs.current.length - 1;
	// 	const isLastInputFilled =
	// 		!inputRefs.current[lastInputIndex].hasAttribute('disabled') &&
	// 		inputRefs.current[lastInputIndex].value !== '';
	// 	setIsButtonActive(isLastInputFilled);
	// };

	const handlePaste = (event) => {
		event.preventDefault();
		const pastedValue = (
			event.clipboardData || window.clipboardData
		).getData('text');

		for (let i = 0; i < inputRefs.current.length; i++) {
			if (i < pastedValue.length) {
				inputRefs.current[i].value = pastedValue[i];
				inputRefs.current[i].removeAttribute('disabled');
			} else {
				inputRefs.current[i].value = '';
			}
		}

		if (inputRefs.current[pastedValue.length]) {
			inputRefs.current[pastedValue.length].focus();
		}
	};

	const submitOtp = async (e) => {
		e.preventDefault();
		setTimer(true);

		const otpValues = inputRefs.current.map((ref) => ref.value);
		const otpData = otpValues.join('');

		let formData = new FormData();
		formData.append('company_id', companyId);
		formData.append('user_id', userId);
		formData.append('otp', otpData);

		try {
			setIsLoading(true);
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}otp-verification.php`,
				{
					method: 'POST',
					body: formData,
				}
			);
			const data = await response.json();
			if (response.ok) {
				toastCall();
				setIsLoading(false);
				navigate('/select-location');
			} else {
				toastCallError();
				setIsLoading(false);
			}
		} catch (error) {
			toastCallError();
			setIsLoading(false);
		}
	};

	return (
		<Box
			display='flex'
			alignItems='center'
			justifyContent='center'
			bgColor='#e2e1ff'
			height='100vh'
			w='100vw'>
			<Box
				boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px'
				borderRadius='15px'
				display='flex'
				alignItems='center'
				justifyContent='center'
				flexWrap='wrap'
				bgColor='white'
				p='20px'>
				<Box
					className='otp-verification'
					width={{ base: '100%', md: '400px' }}
					height='350px'
					display='flex'
					alignItems='center'
					justifyContent='center'
					borderRadius='10px'
					textAlign='center'>
					<Box
						display='flex'
						flexDirection='column'
						alignItems='center'
						justifyContent='center'>
						<Image
							src={Logo}
							alt='logo'
							height='auto'
							width='70%'
							mb='20px'
						/>
						<Text mb='20px'>
							Verify Your code was sent to you via email
						</Text>
						<Box className='otp-field' mb='10px'>
							{Array.from({ length: 6 }, (_, index) => (
								<Input
									key={index}
									type='number'
									maxLength={1}
									ref={(ref) =>
										(inputRefs.current[index] = ref)
									}
									onPaste={handlePaste}
									onKeyUp={(event) =>
										handleInputChange(index, event)
									}
									disabled={index !== 0}
								/>
							))}
						</Box>
						{timer === true ? (
							<Text
								fontSize='1.2rem'
								fontWeight='600'
								color='var(--chakra-colors-mainRedColor)'>
								{countdown > 0
									? `Time remaining: ${Math.floor(
											countdown / 60
									  )}:${String(countdown % 60).padStart(
											2,
											'0'
									  )}`
									: ''}
							</Text>
						) : (
							''
						)}
						<Button
							disabled={isLoading}
							isLoading={isLoading}
							spinner={<BeatLoader size={8} color='white' />}
							mt='24px'
							mb='5px'
							bgGradient='linear(180deg, #2267A2 0%, #0D4675 100%)'
							boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
							borderRadius='10px'
							p='20px 40px'
							fontSize='1.6rem'
							color='white'
							onClick={submitOtp}
							_hover={{
								bgGradient:
									'linear(180deg, #2267A2 0%, #0D4675 100%)',
							}}
							_active={{
								bgGradient:
									'linear(180deg, #2267A2 0%, #0D4675 100%)',
							}}
							_focus={{
								bgGradient:
									'linear(180deg, #2267A2 0%, #0D4675 100%)',
							}}
							isDisabled={!isButtonActive ? true : false}>
							Verify
						</Button>

						<Text className='resend text-muted '>
							Didn't receive code?{' '}
							<Button
								onClick={requestButtonClick}
								textColor='blue'
								bg='none'
								_hover={{
									bg: 'none',
								}}
								_active={{
									bg: 'none',
								}}
								_focus={{
									bg: 'none',
								}}
								isDisabled={countdown >= 0 ? true : false}>
								Request again
							</Button>
						</Text>
					</Box>
				</Box>
				<Box
					display={{ base: 'none', md: 'flex' }}
					width={{ base: '100%', md: '400px' }}
					background='white'
					padding='15px'
					height='350px'
					p='20px'
					borderRadius='15px'
					alignItems='center'
					justifyContent='center'>
					<Image src={Vitwo} alt='vitwo ai' />
				</Box>
			</Box>
		</Box>
	);
};

export default OtpScreen;
