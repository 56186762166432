import { Box } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';

const AutoTypeText = React.memo(() => {
	const [text, setText] = useState('');
	const fullText =
		'Please download the excel file and  fill your data as demo wise and upload the file'; // Replace this with the text you want to auto-type
	const typingSpeed = 50; // Adjust the typing speed in milliseconds

	useEffect(() => {
		const interval = setInterval(() => {
			const currentTextLength = text.length;
			const fullTextLength = fullText.length;

			if (currentTextLength === fullTextLength) {
				clearInterval(interval);
			} else {
				setText(fullText.substring(0, currentTextLength + 1));
			}
		}, typingSpeed);

		return () => clearInterval(interval);
	}, [text, fullText]);

	return <Box color='gray.600'>{text}</Box>;
});

export default AutoTypeText;
