// import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
// 	companyId: null,
// 	userId: null,
// 	selectedLocation: null,
// 	selectedBranch: null,
// 	location: null,
// 	locationCode: null,
// 	companyName: null,
// };

// export const combinedReducers = createSlice({
// 	name: 'combinedReducers',
// 	initialState,
// 	reducers: {
// 		setCompanyId: (state, action) => {
// 			state.companyId = action.payload;
// 		},
// 		setUserId: (state, action) => {
// 			state.userId = action.payload;
// 		},
// 		setSelectedLocation: (state, action) => {
// 			state.selectedLocation = action.payload;
// 		},
// 		setSelectedBranch: (state, action) => {
// 			state.selectedBranch = action.payload;
// 		},
// 		setLocation: (state, action) => {
// 			state.location = action.payload;
// 		},
// 		setLocationCode: (state, action) => {
// 			state.locationCode = action.payload;
// 		},
// 		setCompanyName: (state, action) => {
// 			state.companyName = action.payload;
// 		},
// 	},
// });

// export const {
// 	setCompanyId,
// 	setUserId,
// 	setSelectedLocation,
// 	setSelectedBranch,
// 	setLocation,
// 	setLocationCode,
// 	setCompanyName,
// } = combinedReducers.actions;
// export default combinedReducers.reducer;

import { createSlice } from '@reduxjs/toolkit';

export const combinedReducers = createSlice({
	name: 'combinedReducers',
	initialState: {
		companyId: null,
		userId: null,
		selectedLocation: null,
		selectedBranch: null,
		location: null,
		locationCode: null,
		companyName: null,
	},
	reducers: {
		setCompanyId: (state, action) => {
			state.companyId = action.payload;
		},
		setUserId: (state, action) => {
			state.userId = action.payload;
		},
		setSelectedLocation: (state, action) => {
			state.selectedLocation = action.payload;
		},
		setSelectedBranch: (state, action) => {
			state.selectedBranch = action.payload;
		},
		setLocation: (state, action) => {
			state.location = action.payload;
		},
		setLocationCode: (state, action) => {
			state.locationCode = action.payload;
		},
		setCompanyName: (state, action) => {
			state.companyName = action.payload;
		},
	},
});

export const {
	setCompanyId,
	setUserId,
	setSelectedLocation,
	setSelectedBranch,
	setLocation,
	setLocationCode,
	setCompanyName,
} = combinedReducers.actions;
export default combinedReducers.reducer;
