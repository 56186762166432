import React, { useState, useEffect } from 'react';
import { Box, Heading, Select, Text, Button } from '@chakra-ui/react';
import Topbar from '../../global/topbar';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	setSelectedLocation,
	setSelectedBranch,
	setLocation,
	setLocationCode,
	setCompanyName,
} from '../../../features/combinedReducers';

const Selectlocation = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [itemList, setItemList] = useState([]);
	const companyId = useSelector((state) => state.companyId);

	useEffect(() => {
		const dropdownlist = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}location-list.php?company_id=${companyId}`,
					{
						method: 'GET',
					}
				);
				if (response.ok) {
					const data = await response.json();
					setItemList(data?.message);
				} else {
					console.log('Request Faild');
				}
			} catch (error) {
				console.log('Request Faild');
			}
		};
		dropdownlist();
	}, []);

	const handleLocationChange = (event) => {
		const selectedLocationId = event.target.value;
		const selectedLocationData = itemList?.find(
			(location) => location?.othersLocation_id === selectedLocationId
		);

		const companyName = selectedLocationData?.company_name;
		const location = selectedLocationData?.othersLocation_id;
		const branch = selectedLocationData?.branch_id;
		const locationName = selectedLocationData?.othersLocation_city;
		const locationCode = selectedLocationData?.othersLocation_code;

		dispatch(setSelectedBranch(branch));
		dispatch(setSelectedLocation(location));
		dispatch(setLocation(locationName));
		dispatch(setLocationCode(locationCode));
		dispatch(setCompanyName(companyName));
		setIsLoading(false);
	};

	return (
		<Box
			borderRadius='6px'
			display='flex'
			flexDirection='column'
			alignItems='center'
			justifyContent='center'>
			<Topbar />
			<Box
				boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px'
				width='50%'
				marginTop='50px'
				height='250px'
				borderRadius='10px'
				display='flex'
				justifyContent='center'
				flexDirection='column'
				alignItems='center'
				padding='20px 0px'>
				<Heading
					mb='15px'
					color='var(--chakra-colors-mainBlueColor)'
					fontSize='28px'
					textAlign='center'>
					Welcome to {itemList[0]?.company_name}
				</Heading>
				<Text
					mb='15px'
					color='rgb(137,137,137)'
					fontSize='16px'
					fontWeight='600'>
					Please Select Your Branch & Location First!
				</Text>
				<Select
					w='70%'
					mr='10px'
					h='40px'
					fontSize='1.4rem'
					id='locationSelect'
					onChange={handleLocationChange}>
					<option value=''>Select Location</option>
					{itemList?.map((data) => (
						<option
							key={data?.othersLocation_id}
							value={data?.othersLocation_id}>
							{data?.othersLocation_name}
						</option>
					))}
				</Select>
				<Button
					isDisabled={isLoading}
					mt='20px'
					mb='5px'
					bgGradient='linear(180deg, #2267A2 0%, #0D4675 100%)'
					boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
					borderRadius='10px'
					p='20px 40px'
					fontSize='1.6rem'
					color='white'
					_hover={{
						bgGradient: 'linear(180deg, #2267A2 0%, #0D4675 100%)',
					}}
					_active={{
						bgGradient: 'linear(180deg, #2267A2 0%, #0D4675 100%)',
					}}
					_focus={{
						bgGradient: 'linear(180deg, #2267A2 0%, #0D4675 100%)',
					}}
					onClick={() => navigate('/')}>
					Proceed
				</Button>
			</Box>
		</Box>
	);
};

export default Selectlocation;
